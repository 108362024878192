import {Routes, Route, useNavigate, useLocation} from 'react-router-dom'
import ROUTES from './routes'
import NotFoundPage from '../pages/not-found-page'
import MainLayout from '../components/main-layout'
import LoginPage from '../pages/login'
import {locationHistory} from '../helpers/location-history'
import PatientDetailsPage from '../pages/patient-details';
import PatientsPage from '../pages/patients';
import React from 'react';
import MagicLinkProceed from '../pages/magic-link-proceed';

const AppRouter = () => {
    locationHistory.navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    locationHistory.location = useLocation()
    return  <Routes>
        <Route element={<MainLayout />}>
            <Route path={ROUTES.patients.url} element={<PatientsPage />} />
            <Route path={ROUTES.details.url} element={<PatientDetailsPage />} />
        </Route>
        <Route path={ROUTES.login} element={<LoginPage />} />
        <Route path={ROUTES.linkProceed} element={<MagicLinkProceed />} />
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
}

export default AppRouter
