import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import HttpClient from '../../api/api';
import {Patient, PatientSearch, PatientsPage, UpdatePatientSessionDataRequest} from './types';

interface PatientsData {
    current: Patient | null
    items: Patient[] | null;
    total: number;
    offset: number;
}

const initialState: PatientsData = {
    current: null,
    items: null,
    total: 0,
    offset: 0
}

export const getPatients = createAsyncThunk(
    'patients/get',
    async (pageNumber: number) => {
        const response = await HttpClient.patientApi.getPatients<Patient[]>(pageNumber);
        return response.data;
    }
)

export const filterPatients = createAsyncThunk(
    'patients/filter',
    async ( args: { filter: string } ) => {
        const response = await HttpClient.patientApi.filterPatients<PatientSearch>(args.filter);
        return response.data;
    }
)

export const getSessionDownloadLink = createAsyncThunk(
    'patients/sessions/get-download-link',
    async (sessionId: string) => {
        const response = await HttpClient.patientApi.getSessionDownloadLink<string>(sessionId);
        return response.data;
    }
)

export const updateSessionData = createAsyncThunk(
    'patients/sessions/update',
    async ( args: { patient: string, session: string, encodedString: string }) => {
        const response = await HttpClient.patientApi.updateSessionData(args.patient, args.session, { measures: args.encodedString });
        return response.data;
    }
)


const patientsSplice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        clearPatients: (state) => {
            state.items = initialState.items,
            state.offset = initialState.offset,
            state.total = initialState.total
        },
        clearPatientSelection: (state) => {
            state.current = null
        },
        setPatient: (state, action) => {
            state.current = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(filterPatients. fulfilled, (state, action) => {
            state.items = action.payload.found,
            state.total = action.payload.total,
            state.offset = initialState.offset
        })
        builder.addCase(getPatients.fulfilled, (state, action) => {
            state.items = action.payload,
            state.total = action.payload.length,
            state.offset = initialState.offset
        })
    }
})

export const { clearPatients, clearPatientSelection, setPatient } = patientsSplice.actions

export default patientsSplice.reducer;
