import {NavigateFunction} from 'react-router-dom'

export interface ILocationHistory {
    locationHistory: any
    [x: string]: any
    navigate: NavigateFunction,
    location: Location & {state: any}
}

export const locationHistory: ILocationHistory = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigate: null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    location: null
}
