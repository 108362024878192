import {createAction, createSlice} from '@reduxjs/toolkit';

const initialState = '';


const searchSplice = createSlice({
    name: 'patients',
    initialState,
    reducers: {
        search: (state, action) => {
            return action.payload
        },
    },
})

export const { search } = searchSplice.actions

export default searchSplice.reducer;
