// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
function getSqDist(p1, p2) {

    const dx = p1[0] - p2[0],
        dy = p1[1] - p2[1];

    return dx * dx + dy * dy;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
function getSqSegDist(p, p1, p2) {

    let x = p1[0],
        y = p1[1],
        dx = p2[0] - x,
        dy = p2[1] - y;

    if (dx !== 0 || dy !== 0) {

        const t = ((p[0] - x) * dx + (p[1] - y) * dy) / (dx * dx + dy * dy);

        if (t > 1) {
            x = p2[0];
            y = p2[1];

        } else if (t > 0) {
            x += dx * t;
            y += dy * t;
        }
    }

    dx = p[0] - x;
    dy = p[1] - y;

    return dx * dx + dy * dy;
}
// rest of the code doesn't care about point format

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
function simplifyRadialDist(points, sqTolerance) {
    let prevPoint = points[0],

        // eslint-disable-next-line prefer-const
        newPoints = [prevPoint],
        point;

    for (let i = 1, len = points.length; i < len; i++) {
        point = points[i];

        if (getSqDist(point, prevPoint) > sqTolerance) {
            newPoints.push(point);
            prevPoint = point;
        }
    }

    if (prevPoint !== point) newPoints.push(point);

    return newPoints;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
function simplifyDPStep(points, first, last, sqTolerance, simplified) {
    let maxSqDist = sqTolerance,
        index;

    for (let i = first + 1; i < last; i++) {
        const sqDist = getSqSegDist(points[i], points[first], points[last]);

        if (sqDist > maxSqDist) {
            index = i;
            maxSqDist = sqDist;
        }
    }

    if (maxSqDist > sqTolerance) {
        if (index - first > 1) simplifyDPStep(points, first, index, sqTolerance, simplified);
        simplified.push(points[index]);
        if (last - index > 1) simplifyDPStep(points, index, last, sqTolerance, simplified);
    }
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
function simplifyDouglasPeucker(points, sqTolerance) {
    const last = points.length - 1;

    const simplified = [points[0]];
    simplifyDPStep(points, 0, last, sqTolerance, simplified);
    simplified.push(points[last]);

    return simplified;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export function simplify(points, tolerance, highestQuality) {

    if (points.length <= 2) return points;

    const sqTolerance = tolerance !== undefined ? tolerance * tolerance : 1;
    points = highestQuality ? points : simplifyRadialDist(points, sqTolerance);
    points = simplifyDouglasPeucker(points, sqTolerance);

    return points;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export function parseAreas(data) {
    const intervals: {id: number, name: string, start: string, end: string}[] = [];
    let currentInterval = 0;
    for(let i = 1; i < data.length - 1; i++) {
        if(data[i][3] !== '' && data[i + 1][3] !== '' && data[i - 1][3] === '') {
            const start = data[i][0];
            intervals.push({id: currentInterval, name: data[i][3], start, end: ''})
        }
        if(data[i][3] !== '' && data[i - 1][3] !== '' && data[i + 1][3] === '') {
            const end = data[i][0];
            if (!intervals[currentInterval]) {
                intervals.push({id: currentInterval, name: data[i][3], start: data[0][0], end: ''})
            }
            intervals[currentInterval].end = end;
            currentInterval += 1;
        }
    }

    const areas = intervals.map((interval) => {
        return [
            {
                name: interval.name,
                xAxis: interval.start
            },
            {
                xAxis: interval.end
            }
        ]
    })
    return areas;
}

export function rootMeanSquare(values: number[]): number {
    let s = 0.0;
    let c = 0;
    for (let i = 0; i < values.length; i++) {
        ++c;
        s += values[i] * values[i];
    }

    return c == 0 ? 0.0 : Math.sqrt(s / c);
}

export function parseMarkers(date: any[][]) {
    const points: {id: number, value: string, name: string}[] = [];
    let currentMarker = 0;
    for (let i = 0; i < date.length - 1; i++) {
        if (date[i][2] !== '') {
            points.push({id: currentMarker, value: date[i][0], name: date[i][2]})
            currentMarker += 1;
        }
    }

    const markers = points.map((p) => {
        return {
            name: p.name,
            value: p.value
        }
    });

    return markers;
}

export const closest = (number: number, arr: any[][]): number => {
    let current = arr[0][0]
    for (let i = 0; i < arr.length; i++) {
        const el = arr[i][0]
        if (Math.abs(number - el) < Math.abs(number - current)){
            current = el;
        }
    }
    return current;
}