import * as React from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableData, {Column} from '../../types';
import styles from '../../table.module.scss';
import {Box, Button, Stack, useMediaQuery} from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import PlayIcon from '@mui/icons-material/PlayArrow';
import {Patient, Session} from '../../../../../features/patients/types';
import {NavLink} from 'react-router-dom';
import {useAppDispatch} from '../../../../../hooks/hooks';
import {isLoading} from '../../../../../features/loader';
import JSZipUtils from '../../../../../helpers/jszip-utils.js';
import FileSaver from '../../../../../helpers/file-saver.js';
import JSZip from 'jszip';
import { getSessionDownloadLink } from '../../../../../features/patients';


interface Props {
    item: any;
    columns: Column[],
}

export default function CollapsibleRow(props: Props) {
    const { item, columns } = props;
    const [open, setOpen] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const dispatch = useAppDispatch();
    const tableCellClass = !isHover ? styles.tableCell : `${styles.tableCell} ${styles.hover}`
    const tableCellButtonClass = !isHover ? '' : styles.hover;
    const collapsedTableWrapper = !isHover ? styles.collapseTableWrapper : `${styles.collapseTableWrapper} ${styles.hover}`;

    const urlToPromise = (url: string) => {
        return new Promise(function(resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err: any, data: any) {
                if(err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    const getDownloadLink = async (session: Session) => {
        return await dispatch(getSessionDownloadLink(session.id)).unwrap().then((url) => {
            return url
        });
    }

    const exportALLZip = async (patient: Patient) => {
        const zip = new JSZip();
        dispatch(isLoading(true));
        const sessions: Array<Session> = patient.sessions!
        for(const session of sessions) {
            const url = await getDownloadLink(session);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            zip.file(`measure_${session.id}.zip`, urlToPromise(url), { binary: true, createFolders: true });
        }
        zip.generateAsync({type:'blob'})
            .then(function callback(blob) {
                FileSaver.saveAs(blob, `${patient.name}.zip`);
            }, function (e) {
                console.log('ERROR', e)
            });
        dispatch(isLoading(false));
    }

    const exportZip = async (sessionId: string) => {
        dispatch(isLoading(true));
        dispatch(getSessionDownloadLink(sessionId)).unwrap().then((result) => {
            const link = document.createElement('a');
            link.download = 'measures';
            link.href = result;
            link.click();
        }).then(() => {
            dispatch(isLoading(false));
        })
    }

    const handleMouseOver = (v: any) => {
        setIsHover(true);
    }

    const handleMouseOut = (v: any) => {
        setIsHover(false);
    }

    return (
        <>
            <TableRow role="checkbox" tabIndex={-1} key={item.id}
                className={styles.tableRow}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                sx={{ borderRadius: !open ? '8px 8px 8px 8px' : '8px 8px 0px 0px' }}
                style={!isDesktop ? { display: 'block' } : { display: 'table-row' }}
            >
                {columns.map((column, index) => {
                    const value = item[column.id];
                    return (
                        <TableCell key={column.id} align={column.align}
                            className={tableCellClass}
                            style={{ display: !isDesktop ? 'block' : 'table-cell' }}
                            sx={{
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                                borderTopLeftRadius: index == 0 ? '8px !important' : 0,
                                borderBottomLeftRadius: index == 0 && !open ? '8px !important' : 0,
                                borderBottomColor: open ? 'transparent !important' : 'unset' 
                            }}>
                            {column.format
                                ? column.format(item, open, isDesktop)
                                : value}
                        </TableCell>
                    );
                })}
                <TableCell align='right' sx={
                    {
                        border: 'none',
                        padding: 0,
                        paddingTop: !isDesktop ? '16px !important' : 'unset',
                        paddingBottom: !isDesktop ? '16px !important' : 'unset',
                        borderBottomColor: open ? 'transparent !important' : 'unset' 
                    }} className={tableCellButtonClass}>
                    {
                        item.sessions && item.sessions.length > 0 && <Button variant='export' onClick={() => exportALLZip(item)} startIcon={<IosShareIcon sx={{ width: 16, height: 16 }} />}>Export all data</Button>
                    }
                </TableCell>
                {
                    isDesktop && <TableCell align='right' sx={
                        {
                            border: 'none',
                            padding: 0,
                            textAlign: 'end',
                            borderTopRightRadius: '8px',
                            borderBottomRightRadius: open ? 0 : '8px',
                            borderBottomColor: open ? 'transparent !important' : 'unset' 
                        }} className={tableCellButtonClass}>
                        {item.sessions && item.sessions.length > 0 && <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>}
                    </TableCell>
                }
                {!isDesktop && item.sessions && item.sessions.length > 0 && <div className={styles.mobileCollapse}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </div>}
            </TableRow>
            <TableRow sx={{ position: 'relative' }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}>
                <TableCell style={{ padding: 0 }} colSpan={6}>
                    {item.sessions && <Collapse in={open} sx={{marginTop: '-8px'}}>
                        <Box className={collapsedTableWrapper}>
                            <hr />
                            <Table aria-label="table" className={styles.collapseTable}>
                                <TableBody>
                                    <TableRow className={styles.tableCollapseRow}>
                                        <TableCell colSpan={3} className={styles.headerCollapse} sx={{ minWidth: 100, maxWidth: 300, width: 300 }}>
                                            <span>{item.sessions.length} measures:</span>
                                        </TableCell>
                                    </TableRow>
                                    {item.sessions?.map((session: Session, index: number) =>
                                        <TableRow key={index} className={styles.tableCollapseRow} style={!isDesktop ? { display: 'block' } : { display: 'table-row' }}>
                                            <TableCell sx={{ border: 'none', minWidth: 100, maxWidth: 300 }} style={!isDesktop ? { display: 'block' } : { display: 'table-cell' }}>{session.context}</TableCell>
                                            <TableCell style={!isDesktop ? { display: 'block' } : { display: 'table-cell' }}>{session.startDate}</TableCell>
                                            <TableCell align='right' style={!isDesktop ? { display: 'flex', flexDirection: 'row', gap: '16px', justifyContent: 'stretch' } : { display: 'table-cell' }}>
                                                <NavLink to={`details/${item.id}?sessionId=${session.id}`} style={{ width: !isDesktop ? '100%' : 'unset' }}>
                                                    <Button variant='showMeasures'
                                                        startIcon={<PlayIcon sx={{ width: 16, height: 16 }} />}
                                                        sx={{ width: !isDesktop ? '100%' : 'unset' }}>
                                                        Show Measure
                                                    </Button>
                                                </NavLink>
                                                <Button variant='export'
                                                    onClick={() => exportZip(session.id)}
                                                    startIcon={<IosShareIcon sx={{ width: 16, height: 16 }} />}
                                                    sx={{ marginLeft: isDesktop ? '16px' : 0, width: !isDesktop ? '100%' : 'unset' }}>
                                                    Export
                                                </Button>
                                            </TableCell>
                                        </TableRow>)}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>}
                </TableCell>
            </TableRow>
        </>
    );
}