import { Formik } from 'formik'
import React, { useEffect } from 'react'
import CreateModal from '../../../../components/shared/modal/create-modal'
import { Button, Dialog, DialogActions, DialogContent, TextField, styled } from '@mui/material'
import useModal from '../../../../hooks/useModal';
import { AddNewObjectForm } from '../types';
import * as Yup from 'yup';
import ContentWrapper from '../../../../components/shared/modal/content-wrapper';

export interface Props {
    createNewMarker: boolean;
    onComplete: (result?: AddNewObjectForm) => void;
}

const CreateNewMarkerModal = ({ createNewMarker, onComplete }: Props) => {
    const { open, onToggle } = useModal();
    const initialValues: AddNewObjectForm = {
        name: '',
    }

    const validation: Yup.Schema<AddNewObjectForm> = Yup.object<AddNewObjectForm>().shape({
        name: Yup.string()
            .max(255, 'Too long name')
            .required('Required')
    });

    const add = async (values: AddNewObjectForm) => {
        onComplete(values);
        onToggle();
    }

    const cancel = async () => {
        onComplete();
        onToggle();
    }

    useEffect(() => {
        if (createNewMarker) {
            onToggle()
        }
    }, [createNewMarker])

    return <>
        {
            open && <CreateModal open={open} title={'New marker'} onClose={cancel}>
                <Formik initialValues={initialValues} validationSchema={validation} onSubmit={(values) => add(values)}>
                    {
                        ({ handleSubmit, values, errors, touched, handleBlur, handleChange }) => {
                            return (<>
                                <form onSubmit={handleSubmit}>
                                    <ContentWrapper onCancel={cancel} onOk={() => handleSubmit()} successTitle='Add'>
                                        <TextField error={touched.name && Boolean(errors.name)} helperText={touched.name && errors.name} name='name' placeholder='Name' value={values.name} onChange={handleChange} onBlur={handleBlur} fullWidth></TextField>
                                    </ContentWrapper>
                                </form>
                            </>)
                        }
                    }
                </Formik>
            </CreateModal>
        }
    </>
}

export default CreateNewMarkerModal;