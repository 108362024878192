import React, { useEffect } from 'react';
import DeleteModal from '../../../../components/shared/modal/delete-modal';
import useModal from '../../../../hooks/useModal';
import { Typography } from '@mui/material';
import ContentWrapper from '../../../../components/shared/modal/content-wrapper';

interface Props {
    selection: boolean;
    onComplete: (result: boolean) => void;
}

const DeleteSelectionModal = (props: Props) => {
    const { selection, onComplete } = props;
    const { open, onToggle } = useModal();

    const save = () => {
        onComplete(true);
        onToggle();
    }

    const cancel = async () => {
        onComplete(false);
        onToggle();
    }

    useEffect(() => {
        if (selection) {
            onToggle()
        }
    }, [selection])

    return <>
        {
            open && <DeleteModal open={open} title={'Selected object'} onClose={cancel}>
                <ContentWrapper onCancel={cancel} onOk={() => save()} successTitle='Yes'>
                    <Typography>
                        Are you sure that you want to delete Mark under your selection?
                    </Typography>
                </ContentWrapper>
            </DeleteModal>
        }
    </>
}

export default DeleteSelectionModal;