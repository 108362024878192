import React, { ReactNode } from 'react'
import BaseModal from './base-modal';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
}

export default function CreateModal(props: Props) {
    const { open, title, onClose } = props;

    return <BaseModal open={open} title={`Add ${title}`} onClose={onClose} successTitle='Add'>
        {props.children}
    </BaseModal>
}