import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import HttpClient from '../../api/api';
import { UserProfile } from './types';

interface UserData {
    userProfile: UserProfile
}

const initialState: UserData = {
    userProfile: {
        username: ''
    }
}


export const getProfile = createAsyncThunk(
    'user/get-profile',
    async () => {
        const response = await HttpClient.userApi.getProfile<UserProfile>();
        return response.data;
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        clearProfile: (state) => {
            state.userProfile = initialState.userProfile
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProfile.fulfilled, (state, action) => {
            state.userProfile = action.payload;
        })
    }
})

export const { clearProfile } = userSlice.actions;
export default userSlice.reducer;