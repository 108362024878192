import React, { useEffect } from 'react'
import {Outlet} from 'react-router-dom'
import styles from './main-layout.module.scss';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {Backdrop, CircularProgress} from '@mui/material';
import {StateStatus} from '../../features/loader/types';
import Header from '../header';
import {PrivateZone} from '../../routes/PrivateZone';

export default function MainLayout() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(store => store.loader.status);

    return (
        <div className={styles.mainContainer}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading === StateStatus.PENDING}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Header />
            <React.Suspense fallback={<div>Loading...</div>}>
                <PrivateZone>
                    <Outlet />
                </PrivateZone>
            </React.Suspense>
            <div id='modals'></div>
        </div>
    )
}
