import React, { forwardRef, FC } from 'react';
import { ToggleButton, ToggleButtonProps, Tooltip, TooltipProps } from '@mui/material';

type TooltipToggleButtonProps = ToggleButtonProps & {
    TooltipProps: Omit<TooltipProps, 'children'>;
};

// Catch props and forward to ToggleButton
const TooltipToggleButton: FC<TooltipToggleButtonProps> = forwardRef(
    // eslint-disable-next-line
    ({ TooltipProps, ...props }, ref) => {
        return (
            <Tooltip {...TooltipProps}>
                <ToggleButton ref={ref} {...props} />
            </Tooltip>
        );
    }
);

TooltipToggleButton.displayName = 'TooltipedToggleButton';
export default TooltipToggleButton;