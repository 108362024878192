import {configureAxios} from './axios'
import {initFakeAxios} from './fakeAxios'
import {AxiosInstance} from 'axios'
import AuthAPI, {IAuthAPI} from '../features/auth/authAPI';
import PatientApi, {IPatientApi} from '../features/patients/api';
import UserApi, { IUserApi } from '../features/user/api';

export interface IHttpClient {
    authApi: IAuthAPI;
    patientApi: IPatientApi;
}

class HttpClient implements IHttpClient {
    private readonly _axiosInstance: AxiosInstance;
    private static _instance: HttpClient;
    public authApi: IAuthAPI;
    public patientApi: IPatientApi;
    public userApi: IUserApi;

    constructor() {
        this._axiosInstance = configureAxios()

        if(process.env.REACT_APP_IS_MOCK_AXIOS == 'true') {
            initFakeAxios(this._axiosInstance)
        }
        this.authApi = new AuthAPI(this._axiosInstance);
        this.patientApi = new PatientApi(this._axiosInstance);
        this.userApi = new UserApi(this._axiosInstance);
    }

    public static get Instance() {
        return this._instance || (this._instance = new this())
    }
}

export default HttpClient.Instance
