import {AxiosInstance, AxiosResponse} from 'axios';
import {ApiAuthSecurityRequest, IApiAuthResponse} from '../../api/types';
import Cookies from 'js-cookie';
import exp from 'constants';

export interface IAuthAPI {
    login: (code: string) => Promise<AxiosResponse<string>>;
    validateSession: (auth: {code: string}) => Promise<AxiosResponse<string>>;
    authenticate: (query: string) => Promise<AxiosResponse>;
    sendMagicLink: (email: string) => Promise<AxiosResponse<string>>;
    challengeCode: (email: string) => Promise<AxiosResponse<string>>;
    authenticateByCode: (body: { stateToken: string, authCode: string }) => Promise<AxiosResponse<string>>;
    token: string | null;
}

export default class AuthAPI implements IAuthAPI{
    private readonly _axiosInstance;
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }

    public async login(code: string): Promise<AxiosResponse<string>> {
        return await this._axiosInstance.get(`login?code=${code}`);
    }

    public get token(): string | null {
        const token = localStorage.getItem('token');
        if (token === '' || token === null) {
            return null;
        } else {
            return token;
        }
    }

    public async challengeCode(email: string): Promise<AxiosResponse<string>> {
        const url = `login/code/challenge?email=${email}`;
        return await this._axiosInstance.get(url);
    }

    public async authenticateByCode(body: { stateToken: string, authCode: string }): Promise<AxiosResponse<string>> {
        return await this._axiosInstance.post('login/code', JSON.stringify(body), {headers: {'Content-Type': 'application/json'}})
    }

    public async sendMagicLink(email: string): Promise<AxiosResponse<string>> {
        const url = `user/email-magic-link/challenge?original_url=${window.location.origin}&email=${email}`
        return await this._axiosInstance.get(url);
    }

    public async validateSession(body: { code: string; }): Promise<AxiosResponse<string>> {
        return await this._axiosInstance.post('session/status', JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    
    public async authenticate(query: string): Promise<AxiosResponse> {
        return await this._axiosInstance.get(`user/email-magic-link${query}`);
    }
}
