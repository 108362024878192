import React, {useEffect, useRef, useState} from 'react'
import styles from './styles.module.scss';
import {SVGS} from '../../assets/images.js';
import {Box, Button, CircularProgress, InputBase, TextField, styled} from '@mui/material';
import {useAppDispatch, useAppSelector, useCookies} from '../../hooks/hooks';
import {authenticateByCode, challengeCode, sendMagicLink} from '../../features/auth/authSplice';
import Cookies from 'js-cookie';
import AuthCode, { AuthCodeRef } from '../../components/shared/auth-code-input';

const LoginPage = () => {
    const [code, setCode] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const dispatch = useAppDispatch();
    const [sent, setSent] = useState<boolean>(false);
    const auth = useAppSelector(x => x.auth);
    const AuthInputRef = useRef<AuthCodeRef>(null);
    
    const challenge = (code: boolean) => {
        if (auth.status === 'loading') {
            return;
        }
        if (email === '') {
            setEmailError('Email is empty');
            return;
        }
        if (!code) {
            dispatch(sendMagicLink(email)).then(() => {
                setSent(true);
            })
        } else {
            dispatch(challengeCode(email)).then(() => {
                setSent(true);
            })
        }
    }

    const authenticate = () => {
        if (auth.status === 'loading') {
            return;
        }
        if (code.length === 6) {
            const authData = Cookies.get('auth') as string;
            dispatch(authenticateByCode({ token: authData, code: code }));
        }
    }

    const sendMore = () => {
        if (auth.status === 'loading') {
            return
        }
        setSent(false);
    }

    useEffect(() => {
        if (code.length > 5) {
            setTimeout(() => {
                authenticate();
            }, 500)
        }
    }, [code])

    return <main className={styles.wrapper}>
        <SVGS.LoginLogoIcon />
        {!sent
            ? <Box className={styles.loginBox} mt={3}>
                <Box className={styles.title}>Sign In</Box>
                <Box className={styles.text}>Please provide your email, and we&apos;ll send you a verification code.</Box>
                <TextField
                    onChange={(e) => {
                        setEmailError('');
                        return setEmail(e.target.value)
                    }}
                    value={email}
                    className={emailError !== '' ? styles.email : `${styles.email} ${styles.failed}`}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter'){
                            challenge(true)
                        }
                    }}
                    error={!!emailError}
                    helperText={emailError}
                    type='email'
                    placeholder="Email" />
                <Button variant='login' sx={{ marginTop: '24px' }} onClick={() => challenge(true)}>
                    {
                        auth.status === 'loading' ? <CircularProgress size={17} color='inherit' /> : 'Request code'
                    }
                </Button>
            </Box>
            : <Box className={styles.loginBox} mt={3}>
                <Box className={styles.title}>Verification code</Box>
                <Box className={styles.text}>Please provide the code, that was sent to your email.</Box>
                <AuthCode onChange={(e) => {
                    if (auth.status === 'loading') {
                        return;
                    }
                    return setCode(e);
                }} inputClassName={auth.status === 'failed' ? `${styles.authInput} ${styles.failed}` : styles.authInput} containerClassName={styles.authCodeContainer} ref={AuthInputRef} autoFocus={true} allowedCharacters='numeric' />
                {
                    auth.status === 'failed' && <p className={styles.authError}>{auth.error}</p>
                }
                {
                    auth.status === 'loading' ? <Box display={'flex'} justifyContent={'center'} marginTop={'24px'}> <CircularProgress size={20} color='success' /> </Box> : <Box className={styles.anotherEmail} onClick={sendMore}>Use another email</Box>
                }
            </Box>}
    </main>
}

export default LoginPage
