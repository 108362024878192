import React, { ReactNode, useEffect } from 'react'
import { useAppDispatch } from '../../../hooks/hooks';
import { clearSelected } from '../../../features/modals';
import { Dialog, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';

interface Props {
    children: ReactNode;
    open: boolean;
    title: string;
    onClose: () => void;
    successTitle?: string;
    cancelTitle?: string;
    valid?: boolean;
}

const BaseModal = (props: Props) => {
    const {open, title, onClose, successTitle, cancelTitle, valid} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const CustomDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogTitle-root': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'Inter, sans-serif',
            fontSize: '17px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '0.15px'
        },
        '& .MuiDialogContent-root': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            padding:  theme.spacing(2),
            minWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            '&.MuiDialogContent-dividers': {
                borderColor: '#EAEAEB'
            }
        },
        '& .MuiDialogActions-root': {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            padding: theme.spacing(2),
        },
        '& .MuiPaper-root': {
            borderRadius: 8,
            boxShadow: '0px 5px 16px rgba(0, 0, 0, 0.2)',
        }
    }));
    const dispatch = useAppDispatch();

    const close = () => {
        dispatch(clearSelected())
        onClose();
    }

    return (<React.Fragment>
        <CustomDialog keepMounted open={open} onClose={close} fullScreen={fullScreen}>
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {title}
                <IconButton onClick={close}>
                    <CloseIcon sx={{ color: '#8B8C92' }} />
                </IconButton>
            </DialogTitle>
            {props.children}
        </CustomDialog>
    </React.Fragment>)
}

export default React.memo(BaseModal);