import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {locationHistory} from '../../helpers/location-history'
import HttpClient from '../../api/api'
import Cookies from 'js-cookie';

export interface IAuthState {
    token: string | null;
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: any;
}

const initialState: IAuthState = {
    token: localStorage.getItem('token'),
    error: null,
    status: 'idle'
}

export const login = createAsyncThunk(
    'login',
    async (code: string) => {
        const response = await HttpClient.authApi.login(code)
        return response.data
    }
)

export const sendMagicLink = createAsyncThunk(
    'send',
    async (email: string) => {
        const response = await HttpClient.authApi.sendMagicLink(email)
        return { code: response.data }
    }
)

export const validateSession = createAsyncThunk(
    'validate',
    async (args: {code: string, email: string}) => {
        const response = await HttpClient.authApi.validateSession({
            code: args.code
        });

        return response.data;
    }
)

export const authenticate = createAsyncThunk(
    'authenticate',
    async (query: string) => {
        const response = await HttpClient.authApi.authenticate(query);
        return response.data;
    }
)

export const challengeCode = createAsyncThunk(
    'challenge-code',
    async (email: string) => {
        const response = await HttpClient.authApi.challengeCode(email);
        return response.data;
    }
)

export const authenticateByCode = createAsyncThunk(
    'authenticate-by-code',
    async (args: {code: string, token: string}) => {
        const response = await HttpClient.authApi.authenticateByCode({ stateToken: args.token, authCode: args.code });
        return response.data;
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null
            localStorage.removeItem('token')
            locationHistory.navigate('/login')
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMagicLink.fulfilled, (state, action) => {
                const prevData = Cookies.get('auth');
                if (prevData || prevData !== ''){
                    Cookies.remove('auth')
                }
                const expires = new Date();
                expires.setMinutes(expires.getMinutes() + 10);
                Cookies.set('auth', JSON.stringify(action.payload), { secure: true, expires: expires, sameSite: 'strict' })
            })
            .addCase(validateSession.fulfilled, (state, action) => {
                localStorage.setItem('token', action.payload);
                Cookies.remove('auth')
                window.location.replace('/')
            })
            .addCase(validateSession.rejected, (action) => {
                Cookies.remove('auth')
            })
            .addCase(authenticate.pending, (state) => {
                state.error = null,
                state.status = 'loading'
            })
            .addCase(authenticate.fulfilled, (state) => {
                state.status = 'complete'
            })
            .addCase(authenticate.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
                state.error = 'error'
            })
            .addCase(challengeCode.fulfilled, (state, action) => {
                const prevData = Cookies.get('auth');
                if (prevData || prevData !== ''){
                    Cookies.remove('auth')
                }
                const expires = new Date();
                expires.setMinutes(expires.getMinutes() + 10);
                Cookies.set('auth', action.payload, { secure: true, expires: expires, sameSite: 'strict' })
            })
            .addCase(authenticateByCode.pending, (state) => {
                state.error = null,
                state.status = 'loading'
            })
            .addCase(authenticateByCode.fulfilled, (state, action) => {
                Cookies.remove('auth')
                localStorage.setItem('token', action.payload);
                window.location.replace('/')
                state.status = 'complete'
            })
            .addCase(authenticateByCode.rejected, (state, action: PayloadAction<any>) => {
                state.status = 'failed'
                state.error = 'Incorrect verification code.'
            })
    },
})

export const { logout } = authSlice.actions
export default authSlice.reducer
