import { Button, DialogActions, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    successTitle?: string;
    cancelTitle?: string;
    onCancel: () => void;
    onOk: () => void;
}
const ContentWrapper = (props: Props) => {
    const theme = useTheme();
    const micro = useMediaQuery(theme.breakpoints.down('md'));
    const padding = micro ? '10px 16px' : '14px 24px';
    return(<>
        <DialogContent dividers>
            {props.children}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel} sx={{ padding: padding }} variant='logout'>{props.cancelTitle || 'Cancel'}</Button>
            <Button onClick={props.onOk} sx={{ padding: padding }} variant='login'>{props.successTitle || 'OK'}</Button>
        </DialogActions>
    </>)
}

export default React.memo(ContentWrapper);