import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import notificationReducer from '../features/snacks/snacksSlice'
import authReducer from '../features/auth/authSplice'
import loaderReducer from '../features/loader'
import modalsReducer from '../features/modals'
import patientsReducer from '../features/patients';
import searchReducer from '../features/search';
import userReducer from '../features/user';

export const store = configureStore({
    reducer: {
        snacks: notificationReducer,
        auth: authReducer,
        loader: loaderReducer,
        modals: modalsReducer,
        patients: patientsReducer,
        search: searchReducer,
        user: userReducer
    },
    //here you can add additional middlewares with concat function
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
