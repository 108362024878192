import {BaseVariant} from 'notistack'
import {enqueueSnack} from './snacksSlice'
import {store} from '../../store'

export const showSnack = (message: string, level: BaseVariant = 'default') => {
    return store.dispatch(enqueueSnack({
        message: message,
        options: {
            key: new Date().getTime() + Math.random(),
            variant: level,
        },
    }))
}
