import React, {useEffect, useMemo, useState} from 'react'
import PageWrapper from '../../components/shared/page-wrapper'
import CustomTable from '../../components/shared/table';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {getPatients} from '../../features/patients';
import styles from './styles.module.scss'
import { SVGS } from '../../assets/images.js';
import { StateStatus } from '../../features/loader/types';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { Patient } from '../../features/patients/types';
import moment from 'moment';
import { isLoading } from '../../features/loader';

export default function PatientsPage() {
    const dispatch = useAppDispatch();
    const loadingStatus = useAppSelector(store => store.loader.status);
    const [loading, setLoading] = useState(false);
    const patients = useAppSelector(x => x.patients);
    const [data, setData] = useState<Array<Patient>>([]);
    const [page, setPage] = useState<number>(0);
    const [searchValue, setSearchValue] = useState('');
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const full = useMemo(() => {
        if (!patients.items) {
            return false;
        }

        return patients.total <= patients.items.length
    }, [patients.total])

    const handleChangePage = async(pageNumber: number) => {
        setPage(pageNumber);
        dispatch(getPatients(pageNumber));
    }

    const find = (data: Patient[], searchString: string): Patient[] => {
        const searchArray = searchString.toLowerCase().split(' ');
        return data.filter(x => {
            return searchArray.every(el => {
                return x.name.toLowerCase().includes(el);
            });
        });
    }

    useEffect(() => {
        if (!patients.items) {
            if (loadingStatus !== StateStatus.PENDING)
                handleChangePage(0);
            return;
        }

        setData(patients.items);
    }, [patients.total, loadingStatus])

    useEffect(() => {
        if (searchValue === '') {
            setData(patients.items!)
        }
        if (patients.items) {
            setData(find(patients.items, searchValue))
        }
    }, [searchValue]);

    const onScroll = (e: any) => {
        // TODO: currently we're loading full data
        // const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 1;
        // if (bottom && !full) {
        //     handleChangePage(page + 1)
        // }
    }

    const sort = (a: Patient, b: Patient): number => {
        const at = a.sessions && a.sessions.length > 0 ? a.sessions[0].endDate : null;
        const bt = b.sessions && b.sessions.length > 0 ? b.sessions[0].endDate : null;
        if (at === null && bt === null) {
            return 0;
        } else if (at === null && bt !== null) {
            return 1;
        } else if (at !== null && bt === null) {
            return -1;
        }

        const atMoment = moment(at);

        if (atMoment.isAfter(bt)){
            return -1;
        } else{
            return 2
        }
    }

    return <PageWrapper>
        <Box sx={{ width: '100%', height: '100%', marginBottom: '40px' }}>
            <TextField
                placeholder="Search by patients"
                variant="outlined"
                className={styles.search}
                sx={{ width: isDesktop ? '390px' : '100%', margin: '0px !important' }}
                value={searchValue}
                onChange={(e) => {
                    if (loadingStatus !== StateStatus.PENDING) { setSearchValue(e.target.value) }
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>,
                    endAdornment: <InputAdornment position='end'>
                        {
                            searchValue !== '' && <IconButton onClick={() => setSearchValue('')}>
                                <ClearIcon />
                            </IconButton>
                        }
                    </InputAdornment>
                }}
            />
            {
                loadingStatus !== StateStatus.PENDING && <>
                    {
                        searchValue !== '' && data.length < 1 ? <div className={styles.notFoundWrapper}>
                            <div className={styles.notFoundIcon}>
                                <SVGS.CloseIcon />
                            </div>
                            <h3>No result</h3>
                            <p>We couldn&apos;t find any patients with this name. Please try another.</p>
                        </div> : <>
                            <div className={styles.patientsFound}>
                                {
                                    searchValue !== '' ? <div className={styles.patientSearch}>
                                        {
                                            isDesktop ? <h1>Found {data.length} of {patients.total}</h1>
                                                : <p>Found {data.length} of {patients.total}</p>
                                        }
                                    </div> : patients.total > 0 ? <h1 className={styles.patientsTotal}>{patients.total} patients</h1> : <></>
                                }
                            </div>
                            {
                                patients && patients.items && patients.total > 0 && data && data.length > 0 ? <div className={styles.patientsTableWrapper}>
                                    <CustomTable data={data} total={data.length} onScroll={onScroll} sort={sort} />
                                </div> : searchValue === '' && <div className={styles.notFoundWrapper}>
                                    <div className={styles.notFoundIcon}>
                                        <SVGS.CloseIcon />
                                    </div>
                                    <h3>You don&apos;t have any patients.</h3>
                                    <p>It is possible to create patients from mobile nurehab application, installed on your smartphone or tablet.</p>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </Box>
    </PageWrapper>
};

