import { ReactComponent as LogoIcon } from './svgs/logo.svg'
import { ReactComponent as LoginLogoIcon } from './svgs/login-logo.svg'
import { ReactComponent as SuccessIcon } from './svgs/success.svg'
import { ReactComponent as CloseIcon } from './svgs/close-icon.svg'
import { ReactComponent as BudgeIcon } from './svgs/budge-icon.svg'
import { ReactComponent as CursorIcon } from './svgs/cursor-icon.svg'
export const SVGS = {
    LogoIcon,
    LoginLogoIcon,
    SuccessIcon,
    CloseIcon,
    BudgeIcon,
    CursorIcon
}