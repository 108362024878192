import { AxiosInstance, AxiosResponse } from 'axios';

export interface IUserApi {
    getProfile<T>(): Promise<AxiosResponse<T>>;
}

export default class UserApi implements IUserApi {
    private readonly _axiosInstance;
    private readonly _controller = 'user';
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance;
    }

    async getProfile<T>(): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this._controller}/profile`);
    }
}