import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {OptionsObject, SnackbarKey} from 'notistack'

export interface SnacksState {
    snacks: any[],
}

const initialState: SnacksState = {
    snacks: [],
}

export const snacksSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        enqueueSnack: (state, action: PayloadAction<{message: string, options: OptionsObject}>) => {
            const key = action.payload.options && action.payload.options.key
            state.snacks = [
                ...state.snacks,
                {
                    key: key || new Date().getTime() + Math.random(),
                    ...action.payload,
                },
            ]
        },
        closeSnack: (state, action: PayloadAction<SnackbarKey>) => {
            state.snacks = state.snacks.map(snack => snack.key === action.payload
                ? { ...snack, dismissed: true }
                : { ...snack }
            )
        },
        removeSnack: (state, action: PayloadAction<SnackbarKey>) => {
            state.snacks = state.snacks.filter(
                snack => snack.key !== action.payload,
            )
        }
    },
})

export const { enqueueSnack, closeSnack, removeSnack } = snacksSlice.actions

export default snacksSlice.reducer
