import React, {ReactElement, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector, useAuthCookies} from '../../hooks/hooks';
import { authenticate, validateSession } from '../../features/auth/authSplice';
import styles from './styles.module.scss';
import { SVGS } from '../../assets/images.js';
import { Box } from '@mui/material';
import { isLoading } from '../../features/loader';

const MagicLinkProceed = (): ReactElement => {
    const location = useLocation();
    const auth = useAppSelector(x => x.auth);
    const authCookies = useAuthCookies();
    const dispatch = useAppDispatch();


    useEffect(() => {
        let complete = false;
        if (complete) {
            return;
        }
        if (auth.status === 'complete' || auth.status === 'loading'){
            return;
        }

        async function signIn() {
            if (!complete) {
                dispatch(isLoading(true))
                dispatch(authenticate(location.search)).unwrap().then(() => {
                    if (authCookies && authCookies.code) {
                        if (auth.status != 'loading'){
                            dispatch(validateSession({ code: authCookies.code, email: authCookies.email })).then(() => {
                                window.location.replace('/')
                            })
                        }
                    }
                }).catch(() => {
                    // nothing
                }).finally(() => {
                    dispatch(isLoading(false))
                } )
            }
        }

        signIn();

        return () => { complete = true }
    }, [location.search])

    return <main className={styles.wrapper}>
        <SVGS.LoginLogoIcon />
        {auth.status === 'loading' 
            ? <Box className={styles.box} mt={3}>
                <Box className={styles.title}>Signing In</Box>
                <Box className={styles.text}>Waiting for service...</Box>
            </Box>
            : !authCookies || authCookies.code === '' && <Box className={styles.box} mt={3}>
                <Box className={styles.title}>Signed In</Box>
                <Box className={styles.text}>You&apos;re successfully authorized. You can close this page and go back to the application.</Box>
            </Box>}
    </main>;
};

export default MagicLinkProceed;
