import {IAppRouter} from './interfaces/IAppRouter'

const ROUTES: IAppRouter = {
    main: '/',
    login: '/login',
    linkProceed: '/user/email-magic-link',
    logout: {
        name: 'Logout',
        url: '/logout',
    },
    patients: {
        name: 'Patients',
        url: '/',
    },
    details : {
        name: 'Details',
        url: '/details/:id',
    }
}

export default ROUTES
