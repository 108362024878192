import React from 'react'
import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
import {store} from './store'
import App from './App'
import './index.css'
import {SnackbarProvider} from 'notistack'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <SnackbarProvider anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
                <App/>
            </SnackbarProvider>
        </Provider>
    </React.StrictMode>
)
