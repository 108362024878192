import React, { useEffect } from 'react';
import useModal from '../../../../hooks/useModal';
import { Field, Form, Formik, FormikProps, useFormik } from 'formik';
import CreateModal from '../../../../components/shared/modal/create-modal';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import ContentWrapper from '../../../../components/shared/modal/content-wrapper';
import { AddNewObjectForm } from '../types';

export interface Props {
    createNewArea: boolean;
    onComplete: (result?: AddNewObjectForm) => void;
}

export default function CreateNewAreaModal({ createNewArea, onComplete }: Props) {
    const { open, onToggle } = useModal();

    const validationSchema = Yup.object<AddNewObjectForm>().shape({
        name: Yup.string()
            .max(255, 'Too long name')
            .required('Required'),
    })

    const initialValues: AddNewObjectForm = {
        name: '',
    }

    const add = async (values: AddNewObjectForm) => {
        onComplete(values);
        onToggle();
    }

    const cancel = async () => {
        onComplete();
        onToggle();
    };

    useEffect(() => {
        if (createNewArea && !open) {
            onToggle()
        }
    }, [createNewArea])

    return <>
        {
            open && <CreateModal open={open} title={'New area'} onClose={cancel}>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => add(values)}>
                    {
                        ({ handleSubmit, values, errors, touched, handleBlur, handleChange }) => {
                            return (<>
                                <form onSubmit={handleSubmit}>
                                    <ContentWrapper onCancel={cancel} onOk={() => handleSubmit()} successTitle='Add'>
                                        <TextField 
                                            error={touched.name && Boolean(errors.name)} 
                                            helperText={touched.name && errors.name} 
                                            name='name' 
                                            placeholder='Name' 
                                            value={values.name} 
                                            onChange={handleChange} 
                                            onBlur={handleBlur} 
                                            fullWidth />
                                    </ContentWrapper>
                                </form>
                            </>)
                        }
                    }
                </Formik>
            </CreateModal>
        }
    </>
}