import React from 'react'
import './App.scss'
import {CssBaseline, ThemeProvider } from '@mui/material'
import { BrowserRouter} from 'react-router-dom'
import AppRouter from './routes/AppRouter'
import theme from './themes/theme'
import useSnacks from './hooks/useSnacks'

function App() {
    useSnacks()
    return (
        <div className="App">
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <AppRouter />
                </BrowserRouter>
            </ThemeProvider>
        </div>
    )
}

export default App
