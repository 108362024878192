import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    Box,
    useMediaQuery
} from '@mui/material';
import {Column} from './types';
import styles from './table.module.scss';
import CollapsibleRow from './components/row-collapse';
import {Patient} from '../../../features/patients/types';
import moment from 'moment';

const columns: Column[] = [
    {
        id: 'name',
        label: 'Name',
        minWidth: 100,
        maxWidth: 300,
        format: (item: Patient, selected?: boolean | null) => {
            return <span style={{ fontSize: '17px', fontWeight: '600', color: selected !== null && selected ? '#4361EE' : '#2F313C' }}>{item.name}</span>
        }
    },
    {
        id: 'measuresNumber',
        label: 'Measures total',
        format: (item: Patient) => {
            return <span>{item.sessions?.length} measures</span>
        }
    },
    {
        id: 'lastMeasure',
        label: 'Last measure',
        format: (item: Patient, _selected?: boolean | null, isDekstop?: boolean | null) => {
            return <span>{item.sessions && item.sessions.length > 0 ? isDekstop ? moment(item.sessions?.[0]?.endDate).format('D ddd YYYY, HH:mm') : `Last measure: ${moment(item.sessions?.[0]?.endDate).format('D ddd YYYY, hh:mm')}` : '-'}</span>
        }
    },
];

interface Props {
    data: Patient[];
    total: number;
    onScroll: (event: any) => void;
    sort: (a: Patient, b: Patient) => number;
}

export default function CustomTable(props: Props) {
    const isDesktop = useMediaQuery('(min-width:1000px)')
    const [patients, setPatients] = useState(props.data && props.data.length > 0 ? [...props.data].sort(props.sort) : []);
    const handleScroll = (e: any) => {
        props.onScroll(e);
    }

    useEffect(() => {
        setPatients(props.data && props.data.length > 0 ? [...props.data].sort(props.sort) : []);
    }, [props.data.length])

    return (<>
        {
            props.total === 0 ? <div>No data</div> :
                <Box sx={{ width: '100%', overflow: 'hidden', marginBottom: '50px' }} id='table-wrapper'>
                    <TableContainer sx={{ maxHeight: '100%', marginBottom: '50px' }} onScroll={handleScroll}>
                        <Table aria-label="table" className={styles.table} sx={{ borderSpacing: '0 8px !important', borderCollapse: 'separate !important', paddingInline: '1rem' }}>
                            {isDesktop && <TableHead>
                                <TableRow sx={{ fontSize: '13px', color: '#6D6F77' }}>
                                    {columns?.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ fontSize: '13px', minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                            sx={{ color: 'var(--table-header-color)', border: 'none' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell align='right' sx={{ border: 'none' }} />
                                    <TableCell align='right' sx={{ border: 'none' }} />
                                </TableRow>
                            </TableHead>}
                            <TableBody>
                                {patients.length > 0 && patients.map((item, index) => <CollapsibleRow item={item} columns={columns} key={index} />)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
        }
    </>);
}
