import React, {ChangeEvent, useEffect} from 'react';
import styles from './styles.module.scss';
import {useMediaQuery, Button} from '@mui/material';
import {SVGS} from '../../assets/images.js';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import IosShareIcon from '@mui/icons-material/IosShare';
import ExitIcon from '@mui/icons-material/ExitToApp';
import { logout } from '../../features/auth/authSplice';
import { getProfile } from '../../features/user';
import { isLoading } from '../../features/loader';

export default function Header() {
    const isDesktop = useMediaQuery('(min-width:1000px)');
    const dispatch = useAppDispatch();
    const user = useAppSelector(store => store.user);

    const loadProfile = async () => {
        await dispatch(getProfile())
    }

    useEffect(() => {
        if (!user || user.userProfile.username === '') {
            loadProfile();
        }
    }, []);
    
    return <header className={styles.header}>
        <div className={styles.logoWrapper}>
            <SVGS.LogoIcon />
        </div>
        <div className={styles.searchWrapper}>
            { user && user.userProfile.username !== '' && <span>{user.userProfile.username}</span> }
            <Button
                variant='logout'
                sx={{ marginLeft: '16px' }}
                size='large'
                className={styles.exitBtn}
                startIcon={<ExitIcon sx={{ width: '16px', height: '16px' }} />}
                onClick={(e) => dispatch(logout())}
            >
                <span className={styles.invisible1000}>Logout</span>
            </Button>
        </div>
    </header>
}