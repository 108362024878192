import {AnyAction, createAction, createSlice, nanoid, PayloadAction} from '@reduxjs/toolkit';
import {StateStatus} from './types';

const initialState = {
    status: StateStatus.FULFILLED,
};

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        isLoading: (state, action: PayloadAction<boolean>) => {
            if(action.payload) {
                state.status = StateStatus.PENDING;
            } else {
                state.status = StateStatus.FULFILLED;
            }
            return state;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action: AnyAction) => action.type.includes('/pending'),
                (state) => {
                    state.status = StateStatus.PENDING;
                }
            )
            .addMatcher(
                (action: AnyAction) => action.type.includes('/fulfilled'),
                (state) => {
                    state.status = StateStatus.FULFILLED;
                }
            )
            .addMatcher(
                (action: AnyAction) => action.type.includes('/rejected'),
                (state) => {
                    state.status = StateStatus.FULFILLED;
                }
            );
    },
});

export const {isLoading} = loaderSlice.actions;

export default loaderSlice.reducer;
