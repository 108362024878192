import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../store'
import Cookies from 'js-cookie'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAuthCookies = () => {
    const auth = Cookies.get('auth');
    if (!auth || auth === '') {
        return {code: '', email: ''}
    }

    return JSON.parse(auth) as { code: string, email: string }
};

export const useCookies = <T>(name: string) => {
    const cookies = Cookies.get(name);
    if (!cookies || cookies === '') {
        return ''
    }

    return JSON.parse(cookies) as T
}
