import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const theme = createTheme({
    components: {
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '20px', /* 133.333% */
                    letterSpacing: '-0.5px'
                }
            }
        },
        MuiToggleButtonGroup : {
            styleOverrides: {
                root: {
                    '& :not(:first-of-type):not(:last-of-type)': {
                        borderLeft: 0,
                        borderRight: 0
                    },
                    '& :first-of-type': {
                        borderRight: 0
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '24px',
                    marginTop: '12px',
                    '&.Mui-error': {
                        color: 'rgba(247, 37, 133, 1)'
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    lineHeight: '19px',
                    color: '#FFFFFF',
                },
            },
            variants: [
                {
                    props: { variant: 'loadMore' },
                    style: {
                        fontWeight: 'bold',
                    }
                },
                {
                    props: { variant: 'export' },
                    style: {
                        color: '#6D6F77',
                        background: '#FFFFFF',
                        border: '1px solid #C5C5C8',
                        borderRadius: 4,
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '13px',
                        // ':hover': {
                        //     background: '#828282',
                        //     color: '#FFFFFF'
                        // }
                    }
                },
                {
                    props: { variant: 'login' },
                    style: {
                        background: '#4361EE',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        padding: '14px 0',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '13px',
                        ':hover': {
                            background: '#4361EE',
                            color: '#FFFFFF'
                        }
                    }
                },
                {
                    props: { variant: 'showMeasures' },
                    style: {
                        color: '#FFFFFF',
                        background: '#4361EE',
                        borderRadius: 4,
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '13px',
                        ':hover': {
                            background: '#4361EE',
                            color: '#FFFFFF'
                        }
                    }
                },
                {
                    props: { variant: 'logout' },
                    style: {
                        background: 'rgba(247, 37, 133, 1)',
                        borderRadius: '8px',
                        color: '#FFFFFF',
                        padding: '14px 0',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '13px',
                        ':hover': {
                            background: 'rgba(247, 37, 133, 1)',
                            color: '#FFFFFF'
                        }
                    }
                }
            ]
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '24px 28px',
                    fontSize: '1rem',
                    border: 'none',
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    background: '#1F1F1F',
                    color: '#565555',
                    border: 'none',
                    marginLeft: '0.8rem'
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.MuiFormControl-root': {
                        border: 'none'
                    },
                    '& .MuiOutlinedInput-root': {
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        borderRadius: '8px',
                        border: '1px solid #EAEAEB',
                        shadowBox: 'none',
                        '&>input': {
                            padding: '12px 12px 12px 16px'
                        },
                        '&:hover': {
                            borderColor: '#8B8C92'
                        },
                        '&.Mui-focused': {
                            borderColor: '#8B8C92'
                        },
                        '&.Mui-error': {
                            borderColor: 'rgba(247, 37, 133, 1)'
                        },
                        '& fieldset': {
                            padding: 0,
                            border: 'none'
                        }
                    }
                }
            },
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        margin: '24px 0 0 0 !important',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        borderRadius: '8px',
                        border: '1px solid #EAEAEB'
                    }
                },
            ]
        }
    },
    palette: {
        primary: {
            main: '#1d1d1d',
        },
        success: {
            main: '#4361EE'
        }
    },
})

export default responsiveFontSizes(theme)
