import {AxiosInstance, AxiosResponse} from 'axios';
import { UpdatePatientSessionDataRequest } from './types';

const PageSize = 25;

export interface IPatientApi {
    getPatients<T>(page: number): Promise<AxiosResponse<T>>
    filterPatients<T>(filter: string): Promise<AxiosResponse<T>>
    getSessionDownloadLink<T>(sessionId: string): Promise<AxiosResponse<T>>
    updateSessionData<T>(patient: string, session: string, body: UpdatePatientSessionDataRequest): Promise<AxiosResponse<T>>
}

export default class PatientApi implements IPatientApi {
    private readonly _axiosInstance;
    private readonly _controller = 'patient';
    constructor(axiosInstance: AxiosInstance) {
        this._axiosInstance = axiosInstance
    }
    async getSessionDownloadLink<T>(sessionId: string): Promise<AxiosResponse<T, any>> {
        return await this._axiosInstance.get<T>(`${this._controller}/session-link?id=${sessionId}`);
    }
    async updateSessionData<T>(patient: string, session: string, body: UpdatePatientSessionDataRequest): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.post<T>(`${this._controller}/${patient}/sessions/${session}/update`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}});
    }
    async getPatients<T>(page: number): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this._controller}/list`);
    }
    async filterPatients<T>(filter: string): Promise<AxiosResponse<T>> {
        return await this._axiosInstance.get<T>(`${this._controller}/filter?q=${filter}`);
    }
}
